import React from "react";
import ContactForm from "../components/ContactForm";
import contactBG from "../images/contact-bg.jpg";
import "@fontsource/league-spartan";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Footer = () => {
    const breakpoints = useBreakpoint();

    const GetInTouchWrapper = {
        paddingTop: 104,
        backgroundImage: `url(${contactBG})`,
        minHeight: 400,
        backgroundSize: "cover",
        backgroundMinSize: 1940,
        backgroundRepeat: "no-repeat",
        padding: breakpoints.sm ? "46px 16px 16px 16px" : "114px 64px 0 64px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 100,
        position: "relative",
        bottom: 0,
      };
    
      const GetInTouchInnerWrapper = {
        display: breakpoints.sm ? "" : "grid",
        gridTemplate: "1fr 50px / 1fr 1fr",
        maxWidth: breakpoints.sm ? "100%" : 986,
        width: breakpoints.sm ? "100%" : 986,
      };
    
    return (
        <div style={GetInTouchWrapper}>
        <div style={GetInTouchInnerWrapper}>
          <div>
            <h3
              style={{
                color: "#FF9800",
                fontSize: 15,
              }}
            >
              Ask us anything
            </h3>
            <h2 style={{ fontSize: 30, marginBottom: 6, marginTop: 0 }}>
              Get in touch
            </h2>
            <h3 style={{ marginBottom: 6 }}>Office address</h3>
            <h4 style={{ fontWeight: 300, margin: 0 }}>Diemermere 1</h4>
            <h4 style={{ fontWeight: 300, margin: 0 }}>1112TA Diemen</h4>
            <h4 style={{ fontWeight: 300, margin: 0 }}>The Netherlands</h4>
          </div>

          <div style={{ justifySelf: "end", marginTop: 16 }}>
            <ContactForm  />
          </div>
        </div>
        <hr
          style={{
            backgroundColor: "#5B5B5B",
            border: "none",
            height: "1px",
            marginTop: 65,
          }}
        />
        <div
          style={{
            gridRow: "2/3",
            gridColumn: "1/3",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ color: "#5B5B5B", fontWeight: 300 }}>
            © 2021 Thunderstock
          </h4>
          <h4 style={{ fontWeight: 300 }}>Privacy statement</h4>
        </div>
      </div>
    )
}

export default Footer;